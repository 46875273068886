<template>
  <div class="tab-container">
    <overlay-loader :loading="isLoading" />
    <hcc-table
      :actualPage="page"
      :rowsPerPage="10"
      :totalPages="total"
      :pagination="total > 10"
      :columns="userColumns"
      :rows="users"
      @search="searchUser"
      @add="addNewUser"
      @edit="editUser"
      @delete="deleteUser"
      @pageChanged="pageChanged"
    />
    <owner-actions
      :edit="isEdit"
      :user="infoUser"
    />
    <hcc-confirmation />
  </div>
</template>

<script>
import executeQuery from '@/utils/gql-api';
import deleteUser from '@/graphql/mutations/deleteUser.gql';
import listCompanyUsers from '@/graphql/queries/users/listByCompanyId.gql';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import EventBus from '@/eventBus';
import errorHandler from '@/utils/error.handler';
import { UPDATE_USERS_OWNERS } from '@/eventTypes';

export default {
  data() {
    return {
      page: 1,
      infoUser: {},
      users: [],
      total: 0,
      isEdit: false,
      isLoading: null,
      role: {},
    };
  },
  components: {
    OverlayLoader,
    OwnerActions: () => import('@/components/users/OwnerActions.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
  },
  created() {
    EventBus.$on(UPDATE_USERS_OWNERS, () => {
      this.fetchUsers(this.page);
    });
  },
  mounted() {
    if (this.companyId !== 0) {
      this.fetchUsers(this.page);
    }
  },
  computed: {
    userColumns() {
      return [{
        label: this.$t('common.name'),
        field: 'name',
      }, {
        label: this.$t('common.email'),
        field: 'email',
      }];
    },
    emptyRows() {
      return this.total === 0;
    },
  },
  methods: {
    async fetchUsers(page, text = null) {
      this.isLoading = true;
      try {
        const data = await executeQuery(
          'listCompanyUsers', listCompanyUsers, {
            roles: ['owner'],
            companyId: this.companyId,
            page,
            perPage: 10,
            text,
          },
          false,
        );

        this.page = page;
        this.total = data.total;
        this.users = data.users;
      } catch (error) {
        errorHandler.logErrors(error);
        this.$toasted.global.error(this.$t('alerts.unexpectedError'));
      } finally {
        this.isLoading = false;
      }
    },
    async pageChanged(page) {
      this.page = page;
      await this.fetchUsers(page);
    },
    deleteUser(props) {
      this.$modal.show('confirmation', {
        title: 'Eliminar Usuario',
        description: this.$t('configuration.confirmation'),
        variant: 'error',
        confirm: async () => {
          const { data: result } = await this.$apollo.mutate({
            mutation: deleteUser,
            variables: {
              userId: props.id,
            },
          });
          this.users = this.users.filter(us => us.id !== result.deleteUser.id);
        },
      });
    },
    editUser(props) {
      this.isEdit = true;
      this.infoUser = props;
      this.$modal.show('Usuario');
    },
    addNewUser() {
      this.isEdit = false;
      this.$modal.show('Usuario');
    },
    async searchUser({ value, key }) {
      if (key && value !== '') {
        this.fetchUsers(1, value);
      } else if (value.length === 0 && !key) {
        this.fetchUsers(1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tab-container {
  position: relative;
}
</style>
